



































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Icon from "@/components/reusable/Icon.vue";
import UIkit from "uikit";

@Component({
  components: {
    Icon
  }
})
export default class Autocomplete extends Vue {
  @Prop() data!: any[];
  @Prop() placeholder!: string;
  @Prop() id?: number | null;
  @Prop({ default: "bottom-left" }) position!: string;
  @Prop({ default: true }) dropdownMode!: boolean;
  protected typing = false;
  // protected suggestions: any[] = [];
  protected value = "";

  // created() {
  //   this.suggestions = this.data.map(item => {
  //   let obj;
  //     if (item.title) {
  //       obj = { display_name: item.display_name, id: item.id, title: item.title }
  //     } else {
  //       obj = { display_name: item.display_name, id: item.id }
  //     }
  //     return obj;
  //   });
  // }

  @Watch("value")
  onChange(): void {
    if (this.value) {
      this.typing = true;
    }
    this.$emit("keyChange", this.value);
  }

  protected update(item: any) {
    // item is the entire object of the data type returned (ex: ProductModel, AttributeModel, etc...)
    this.$emit("updateField", item, this.id);
    if (!this.dropdownMode) {
      if (document.getElementById("move-modal")) {
        UIkit.modal(
          document.getElementById("move-modal") as HTMLElement
        ).hide();
        UIkit.modal(
          document.getElementById("confirm-moving-modal") as HTMLElement
        ).show();
      } else {
        UIkit.modal(
          document.getElementById("related-modal") as HTMLElement
        ).hide();
      }
    }
    this.value = "";
  }

  get matches() {
    // if (this.value) {
    //   const filter = this.data.filter((item) => {
    //     return (
    //       item.display_name.toLowerCase().indexOf(this.value.toLowerCase()) !==
    //       -1
    //     || item.title.toLowerCase().indexOf(this.value.toLowerCase()) !==
    //       -1
    //     );
    //   });
    //   return filter;}
    // } else {
    //   return this.data;
    // }
    return this.data;
  }
}
